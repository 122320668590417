<template>
    <div id="parts-order-bg" class="fill-height">
        <v-overlay :absolute="true" :value="noOrgInfo">
            <org-overlay></org-overlay>
        </v-overlay>
        <v-overlay :absolute="true" :value="$apollo.loading">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>
        <v-container>
            <v-responsive max-width="1000" width="100%" style="display: block; margin: auto">
                <parts-order-stepper
                    v-if="!noModels && !errors"
                    :models="models"
                    :parts-order-warning-message="partsOrderWarningMessage"
                    :is-part-order-paused="isPartOrderPaused"
                ></parts-order-stepper>
                <v-card v-if="noModels" style="margin-top: 25px">
                    <v-card-title class="text-h5"> No registered devices </v-card-title>

                    <v-card-subtitle
                        >You currently have no registered devices. <br />
                        Please visit
                        <a @click="toRegister">"Your Account > Product Registration"</a>
                        to register your devices.<br />
                        <v-btn color="primary" elevation="0" outlined style="margin-top: 25px">
                            <h5 class="heading-line-four"
                                ><a href="/parts-order/manual">View Self-Maintainer Manuals</a></h5
                            >
                        </v-btn>
                    </v-card-subtitle>
                </v-card>
                <v-card v-if="errors && !noOrgInfo" style="margin-top: 25px">
                    <v-card-title class="text-h5"> Error retrieving registered devices </v-card-title>

                    <v-card-subtitle
                        >{{ errorMessage }}
                        <br />
                        <v-btn color="primary" elevation="0" outlined style="margin-top: 25px">
                            <h5 class="heading-line-four"
                                ><a href="/parts-order/manual">View Self-Maintainer Manuals</a></h5
                            >
                        </v-btn>
                    </v-card-subtitle>
                </v-card>
            </v-responsive>
        </v-container>
    </div>
</template>
<script>
import OrgOverlay from '../../components/no_org_overlay'
import PartsOrderStepper from '../../components/parts_order_stepper/index.vue'
import gql from 'graphql-tag'

export default {
    name: `PartsOrder`,

    components: {
        PartsOrderStepper,
        OrgOverlay
    },
    apollo: {
        queryOrg: {
            query: gql`
                query QueryOrg {
                    queryOrg {
                        gssOrgId
                        orgId
                        orgName
                    }
                }
            `,
            update(data) {
                if (!data.queryOrg) {
                    this.noOrgInfo = true
                }
            }
        }
    },
    data() {
        return {
            models: [],
            noModels: false,
            errors: false,
            errorMessage: '',
            noOrgInfo: false,
            partsOrderWarningMessage: '',
            isPartOrderPaused: false
        }
    },
    computed: {
        bannerImg() {
            return '../../assets/banners/11-22_728_x_90-ServicePortal.png'
        }
    },
    created() {
        this.$apollo
            .query({
                query: gql`
                    query QuerySMModel {
                        querySMModel {
                            iwQty
                            model
                            oowQty
                        }
                    }
                `
            })
            .then((data) => {
                console.log(data)
                if (data.data.querySMModel.length === 0) {
                    this.noModels = true
                }
                this.models = data.data.querySMModel
            })
            .catch((errors) => {
                this.errors = true
                this.errorMessage = errors.message
            })
        // query parts order warning period
        this.$apollo
            .query({
                query: gql`
                    query QueryPartsOrderWarning($reportId: String!, $params: String) {
                        queryCommonReport(reportId: $reportId, params: $params)
                    }
                `,
                variables: {
                    reportId: 'QUERY_PARTS_ORDER_WARNING',
                    params: '{}'
                }
            })
            .then((data) => {
                const parsed = data.data.queryCommonReport.map((e) => JSON.parse(e))
                if (parsed && parsed.length > 0) {
                    this.isPartOrderPaused = parsed[0].isPaused
                    if (parsed[0].isWarning || parsed[0].isPaused) this.partsOrderWarningMessage = parsed[0].message
                }
            })
    },
    methods: {
        toRegister() {
            this.$router.push({ path: '/your-account/product-registration' })
        }
    }
}
</script>

<style scoped>
#parts-order-bg {
    background-color: #485461;
    background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
}
</style>
